import React from 'react';
const EditIcon = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.19524 0.528514C9.45559 0.268165 9.8777 0.268165 10.1381 0.528514L13.4714 3.86185C13.7317 4.1222 13.7317 4.54431 13.4714 4.80466L4.80472 13.4713C4.67969 13.5963 4.51012 13.6666 4.33331 13.6666H0.99998C0.63179 13.6666 0.333313 13.3681 0.333313 12.9999V9.66659C0.333313 9.48977 0.403551 9.32021 0.528575 9.19518L9.19524 0.528514ZM1.66665 9.94273V12.3333H4.05717L12.0572 4.33325L9.66665 1.94273L1.66665 9.94273Z"
                fill="white"
            />
        </svg>
    );
};

export default EditIcon;
