//DeleteIcon.js
import React from 'react';
const DeleteIcon = () => {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.33331 3.99992C1.33331 3.63173 1.63179 3.33325 1.99998 3.33325H14C14.3682 3.33325 14.6666 3.63173 14.6666 3.99992C14.6666 4.36811 14.3682 4.66659 14 4.66659H1.99998C1.63179 4.66659 1.33331 4.36811 1.33331 3.99992Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66669 2.00008C6.48988 2.00008 6.32031 2.07032 6.19528 2.19534C6.07026 2.32037 6.00002 2.48994 6.00002 2.66675V3.33341H10V2.66675C10 2.48994 9.92978 2.32037 9.80476 2.19534C9.67973 2.07032 9.51016 2.00008 9.33335 2.00008H6.66669ZM11.3334 3.33341V2.66675C11.3334 2.13631 11.1226 1.62761 10.7476 1.25253C10.3725 0.877462 9.86379 0.666748 9.33335 0.666748H6.66669C6.13625 0.666748 5.62755 0.877462 5.25247 1.25253C4.8774 1.62761 4.66669 2.13631 4.66669 2.66675V3.33341H3.33335C2.96516 3.33341 2.66669 3.63189 2.66669 4.00008V13.3334C2.66669 13.8638 2.8774 14.3726 3.25247 14.7476C3.62755 15.1227 4.13625 15.3334 4.66669 15.3334H11.3334C11.8638 15.3334 12.3725 15.1227 12.7476 14.7476C13.1226 14.3726 13.3334 13.8638 13.3334 13.3334V4.00008C13.3334 3.63189 13.0349 3.33341 12.6667 3.33341H11.3334ZM4.00002 4.66675V13.3334C4.00002 13.5102 4.07026 13.6798 4.19528 13.8048C4.32031 13.9298 4.48988 14.0001 4.66669 14.0001H11.3334C11.5102 14.0001 11.6797 13.9298 11.8048 13.8048C11.9298 13.6798 12 13.5102 12 13.3334V4.66675H4.00002Z"
                fill="white"
            />
        </svg>
    );
};

export default DeleteIcon;
