import React, { useState, useRef } from 'react';
import Link from 'next/link';
import Carousel from 'react-elastic-carousel';
import { Typography } from '@mui/material';

import OlympiadGroupCard from 'components/local/cards/OlympiadGroupCard';

import './style.module.css';

const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 2 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
];

const Slider = ({ datas, onClickRow }) => {
    const carouselRef = useRef();
    const [currentIndex, setCurrentIndex] = useState(0);
    let resetTimeout;

    // const movePrev = () => {
    //     if (currentIndex === 0) return;
    //     carouselRef.current.goTo(currentIndex - 1);
    //     setCurrentIndex((prev) => prev - 1);
    // };

    // const moveNext = () => {
    //     if (currentIndex === datas.length) carouselRef.current.goTo(0);
    //     carouselRef.current.goTo(currentIndex + 1);
    //     setCurrentIndex((prev) => prev + 1);
    // };

    const goto = ({ target }) => {
        carouselRef.goTo(Number(target.value));
    };

    return (
        <div className="">
            <div className="flex justify-between items-center">
                <div className="flex flex-nowrap">
                    <Typography variant="h6" sx={{ color: '#ffa04a', marginLeft: 2 }}>
                        Удахгүй болох олимпиад
                    </Typography>
                </div>
            </div>
            <Carousel
                ref={carouselRef}
                pagination={true}
                showArrows={false}
                outerSpacing={0}
                enableAutoPlay
                renderPagination={({ pages, activePage, onClick }) => {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {pages.map((page) => {
                                const isActivePage = activePage === page;
                                return (
                                    <div
                                        key={page}
                                        onClick={() => onClick(page)}
                                        active={isActivePage}
                                        style={{
                                            backgroundColor: 'white',
                                            width: 12,
                                            height: 12,
                                            margin: 3,
                                            borderRadius: 10,
                                            cursor: 'pointer',
                                        }}
                                    ></div>
                                );
                            })}
                        </div>
                    );
                }}
                autoPlaySpeed={3500}
                itemPosition="START"
                breakPoints={breakPoints}
                itemPadding={[0, 10, 0, 0]}
                onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout);
                    if (index + 1 === datas.length) {
                        resetTimeout = setTimeout(() => {
                            carouselRef?.current?.goTo(0);
                        }, 3000); // same time
                    }
                }}
            >
                {datas.map((data, index) => {
                    return (
                        <OlympiadGroupCard
                            key={index}
                            onClick={onClickRow}
                            {...data}
                            buttonText="Бүртгүүлэх"
                        />
                    );
                })}
            </Carousel>
        </div>
    );
};

export default Slider;
