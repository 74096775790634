import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// store
import { GlobalContext } from 'context';

// layouts
import HomeLayout from 'layouts/HomeLayout';

// utils
import useWindowSize from 'utils/useWindowSize';

// actions
import { getNews } from 'context/actions/news';
import { getRecentBooks } from 'context/actions/books';
import { getGroups } from 'context/actions/olympiads';

// components
import Toolbar from 'components/local/pages/Toolbar';
import BookCard from 'components/local/cards/BookCard';
import Slider from 'components/Slider';
import OlympiadGroupCard from 'components/local/cards/OlympiadGroupCard';
import NewsHomeCard from 'components/local/cards/NewsHomeCard';
import LoginCompose from 'components/local/compose/LoginCompose';
import RegisterCompose from 'components/local/compose/SignupCompose';

const useStyles = makeStyles()(() => {
    return {
        line: {
            backgroundColor: 'gray',
            height: 1,
            width: '70%',
            margin: 'auto',
        },
        registerButton: {
            width: 145,
            margin: '25px auto',
            cursor: 'pointer',
            backgroundColor: 'rgb(39 191 164 / 75%)',
            color: 'white',
            '&:hover': {
                backgroundColor: 'rgb(39 191 164 / 100%)',
            },
            display: 'block',
        },
        container1: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            borderRadius: 8,
        },
        container2: {
            background: 'none',
            flexDirection: 'column',
            alignItems: 'center',
        },
        text: {
            fontSize: 11,
            maxWidth: 253,
            color: '#899AB9',
            borderLeft: '1px solid #899AB9',
            lineHeight: 1.2,
            marginLeft: 10,
            paddingLeft: 10,
            fontFamily: 'roboto, sans-serif',
        },
        Button: {
            color: 'white',
            width: 'fit-content',
            padding: '5px 18px',
            fontWeight: 600,
            marginLeft: 5,
            borderRadius: 8,
            backgroundColor: '#185CCC',
        },
    };
});

const HomePage = ({ news, group }) => {
    const { classes } = useStyles();
    const router = useRouter();
    const { state } = useContext(GlobalContext);
    const [login, setLogin] = useState(false);
    // const [news, setNews] = useState(null);
    const [books, setBooks] = useState(null);
    const [register, setRegister] = useState(false);
    const { sizes } = useWindowSize();

    useEffect(() => {
        getRecentBooks('?limit=6').then((result) => {
            setBooks(result);
        });
        // getNews('?limit=7').then((result) => {
        //     setBooks(result.list);
        // });
    }, []);

    const handleLastGroupClick = async () => {
        if (state.auth.loggedIn) {
            router.push('/student/olympiads');
        } else setLogin(true);
    };

    const closeLogin = () => {
        setLogin((prev) => !prev);
    };

    const closeRegister = () => {
        setRegister((prev) => !prev);
    };

    const handleLoginRegister = async () => {
        setLogin(false);
        setRegister(true);
    };

    return (
        <>
            {login && <LoginCompose handleClose={closeLogin} open={login} />}
            {login && (
                <LoginCompose
                    handleClose={closeLogin}
                    handleRegister={handleLoginRegister}
                    open={login}
                />
            )}
            {register && <RegisterCompose handleClose={closeRegister} open={register} />}
            <Container maxWidth="xl" disableGutters={true}>
                <Box
                    sx={{
                        width: '100%',
                        minHeight: 400,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundImage: 'radial-gradient(#1a1d40 0.5px, #0b0d18 0.5px)',
                        backgroundSize: '10px 10px',
                    }}
                >
                    <Container maxWidth="lg">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Grid container spacing={3} style={{ alignItems: 'center' }}>
                                <Grid item lg={8} sm={12} md={12} xs={12} style={{ padding: 0 }}>
                                    {news.list.length > 0 && (
                                        <Box style={{ marginLeft: 24 }}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: '#ff4a4a',
                                                    maxWidth: 500,
                                                }}
                                            >
                                                Онцлох мэдээ
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: 'white',
                                                    fontSize: 20,
                                                    maxWidth: 500,
                                                    lineHeight: 1.3,
                                                    margin: '10px 0px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '3',
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {news.list[0].title}
                                            </Typography>
                                            <Box
                                                variant="h6"
                                                sx={{
                                                    color: '#899AB9',
                                                    fontSize: 13,
                                                    maxWidth: 500,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '5',
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {news.list[0].shortContent}
                                            </Box>
                                            <Link href={`/news/${news.list[0].id}`} passHref>
                                                <Button
                                                    className={classes.Button}
                                                    style={{ margin: '10px 0px' }}
                                                >
                                                    Дэлгэрэнгүй
                                                </Button>
                                            </Link>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item lg={4} sm={12} md={12} xs={12} style={{ padding: 0 }}>
                                    {group.list.length > 0 && (
                                        <Box>
                                            <Slider
                                                datas={group.list}
                                                onClickRow={handleLastGroupClick}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Container>
            <Container maxWidth={'lg'}>
                <Box
                    sx={{
                        background: 'none',
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Toolbar name="Мэдээ мэдээлэл"></Toolbar>
                    <Grid container spacing={4}>
                        {news.list.map((item, index) => {
                            if (index === 0) return;
                            return (
                                <Grid key={index} item lg={4} md={6} sm={12} xs={12}>
                                    <Link href={`/news/${item.id}`} passHref>
                                        <NewsHomeCard data={item} height={300} />
                                    </Link>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
                <Box
                    sx={{
                        background: 'none',
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Toolbar name="Эрэлт ихтэй номнууд"></Toolbar>
                    <Grid container spacing={3}>
                        {books &&
                            books.list.map((item, i) => (
                                <Grid key={i} item lg={2} md={6} sm={6} xs={6}>
                                    <Link href={`/books/${item.id}`} passHref>
                                        <a>
                                            <BookCard key={item.id} data={item} />
                                        </a>
                                    </Link>
                                </Grid>
                            ))}
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

HomePage.Layout = HomeLayout;

export default HomePage;

export async function getServerSideProps() {
    // res.setHeader('Cache-Control', 'public, s-maxage=43200, stale-while-revalidate=60');
    // const books = await getRecentBooks('?limit=6');
    const news = await getNews('?limit=7');
    const group = await getGroups('?limit=3&status=1');

    return {
        props: { news, group }, // will be passed to the page component as props
    };
}
