import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    description: {
        color: '#949494',
        marginBottom: 5,
        fontSize: 12,
        fontWeight: 400,
    },
    title: {
        color: 'black',
        marginBottom: 5,
        fontSize: 18,
        fontWeight: 600,
    },
}));

export default function AlertDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props]);

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    const handleAgree = () => {
        setOpen(false);
        props.onAgree();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title" className={classes.title}>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.description} id="alert-dialog-description">
                    {props.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Үгүй</Button>
                <Button onClick={handleAgree} autoFocus>
                    Тийм
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AlertDialog.defaultProps = {
    title: 'Баталгаажуулах асуулт',
    description:
        'Та тус бичлэгийг бүр мөсөн устгах гэж байна. Устгавал түүнд хамааралтай бүх бичлэг устгагдах болохыг анхаарна уу!',
};
