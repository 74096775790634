import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

const useStyles = makeStyles()(() => {
    return {
        root: {},
        title: {
            marginTop: 10,
            maxHeight: 37,
            overflow: 'hidden',
            color: 'black',
            textOverflow: 'ellipses',
        },
        radTitle: {
            width: '100%',
            backgroundColor: '#00000078',
            fontSize: 10,
            padding: 15,
            backdropFilter: 'blur(3px)',
        },
        newsTitle: {
            fontSize: 13,
            lineHeight: 1.3,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
    };
});

const NewsHomeCard = (props) => {
    const { classes } = useStyles();

    return (
        <Box
            sx={{
                display: 'flex',
                cursor: 'pointer',
                height: '100%',
            }}
            onClick={props.onClick}
        >
            <Card sx={{ width: '100%' }}>
                <CardMedia sx={{ height: 170 }} image={props.data.imagePath} />
                <CardContent style={{ minHeight: 120 }}>
                    <Typography gutterBottom variant="h3" component="div">
                        {props.data && props.data.title}
                    </Typography>
                    <Typography variant="h6" color="gray">
                        {props.data && props.data.shortContent}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">Дэлгэрэнгүй</Button>
                </CardActions>
            </Card>
        </Box>
    );
};

NewsHomeCard.defaultProps = {
    data: {
        title: 'Бизнес эрхлэгчдийн асуудлыг шийдэхэд GOOD платформ тусална',
        imageUrl: 'https://content.ikon.mn/news/2022/2/23/pr8dqe_DSC_4901_x974.jpg',
        createAt: '2020/01/01',
        content:
            'Тэгвэл энэ бүх асуудлыг шийдэх нийлүүлэлтийн гинжин хэлхээ болох GOOD платформ 2022 оны хоёрдугаар сарын 22-ны өдөр нээлтээ хийлээ.',
    },
    width: '100%',
    height: '100%',
};

NewsHomeCard.propTypes = {
    title: PropTypes.string,
    createdAt: PropTypes.string,
};

export default NewsHomeCard;
