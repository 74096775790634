import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, Typography } from '@mui/material';

import Image from 'next/image';
import moment from 'moment';
import DeleteIcon from 'public/admin/svg/trash';
import EditIcon from 'public/admin/svg/edit';
import AwardIcon from 'public/banner/teacher.png';

import AlertDialog from 'components/global/helpers/AlertDialog';

import ImageLoader from 'utils/ImageLoader';

const useStyles = makeStyles()(() => {
    return {
        container: {
            cursor: 'pointer',
            margin: 12,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 8,
            backgroundColor: '#f7f7f7',
            padding: '30px 0 30px',
            transition: 'all 0.1s ease-in-out',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: '#f3f3f300',
            boxShadow: '0px 1px 10px #14193d3d',
            '&:hover': {
                borderColor: '#bec5d3',
                '& $actionBox': {
                    display: 'flex',
                },
            },
        },
        lessonBox: {
            borderRadius: 20,
            backgroundColor: '#d9e6ff',
            padding: '3px 14px',
            color: '#175ccc',
            marginLeft: 5,
        },
        lesson: {
            color: '#185CCC',
            fontWeight: 600,
            textAlign: 'center',
            fontSize: 12,
        },
        title: {
            width: '80%',
            color: '#252525',
            textAlign: 'center',
            lineHeight: 1.3,
        },
        src: {
            width: '80%',
            borderRadius: 4,
        },
        lineContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '20px 0px 3px 00px',
        },
        start: {
            color: '#899AB9',
            margin: '0px 5px',
        },
        line: {
            height: 1,
            width: 79,
            backgroundColor: '#cad4e9',
        },
        timerBox: {
            width: 147,
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
        },
        time: {
            fontSize: 17,
            color: '#000000',
            fontWeight: 600,
        },
        timerText: {
            fontSize: 12,
            opacity: 0.5,
        },

        actionBox: {
            // display: 'none',
            // position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        sign: {
            color: 'white',
            width: 'fit-content',
            padding: '5px 18px',
            margin: 5,
            fontWeight: 600,
            borderRadius: 8,
            backgroundColor: '#185CCC',
            marginTop: 10,
            '&:hover': {
                backgroundColor: '#114499',
            },
        },
        actionButton: {
            width: 30,
            height: 30,
            padding: 3,
            margin: 2,
            background: '#6B6F7E',
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 0.3s ease-in-out',
            cursor: 'pointer',
            '& svg': {
                marginTop: -1,
                position: 'absolute',
            },
            '&:hover': {
                opacity: 0.5,
            },
        },
        buttons: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 10,
        },
    };
});

const OlympiadCard = (props) => {
    // const classes = useStyles();
    const { classes } = useStyles();
    const [diffTime, setDiffTime] = useState(time);
    const [isConfirm, setConfirm] = useState(false);
    const [countdownDate, setCountdownDate] = useState(props.startTime);

    useEffect(() => {
        setNewTime();
        // clearInterval();
        setInterval(() => setNewTime(), 60000);
    }, []);

    const setNewTime = () => {
        if (countdownDate) {
            let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
            let duration = moment.duration(moment(new Date(countdownDate)).diff(moment()));
            let days = Math.floor(duration.asDays());
            let hours = duration.hours();
            let minutes = duration.minutes();
            if (numbers.includes(hours)) hours = `0${hours}`;
            if (numbers.includes(minutes)) minutes = `0${minutes}`;
            setDiffTime({ Өдөр: `${days}`, Цаг: `${hours}:`, Минут: minutes });
        }
    };

    const handleDelete = () => {
        setConfirm(true);
    };

    const handleEdit = () => {
        props.onEdit();
    };

    const onAgreeDelete = () => {
        props.onDelete(props.id);
        setConfirm(false);
    };

    return (
        <>
            <AlertDialog
                onAgree={onAgreeDelete}
                onClose={() => setConfirm(false)}
                open={isConfirm}
            />
            <Box className={classes.container}>
                <Typography variant="h6" className={classes.title}>
                    {props.category.name}
                </Typography>
                <Typography variant="h6" className={classes.title}>
                    {props.name}
                </Typography>
                <Box style={{ display: 'flex', margin: 10 }}>
                    <Box className={classes.lessonBox}>
                        <Typography className={classes.lesson}>{props.lesson.name}</Typography>
                    </Box>
                    {props.amount > 0 && (
                        <Box className={classes.lessonBox}>
                            <Typography className={classes.lesson}>{props.amount} ₮</Typography>
                        </Box>
                    )}
                    {props.amount === 0 && (
                        <Box className={classes.lessonBox}>
                            <Typography className={classes.lesson}>Төлбөргүй</Typography>
                        </Box>
                    )}
                    {/* <Box className={classes.lessonBox}>
                    <Typography className={classes.lesson}>{props.grade}</Typography>
                </Box> */}
                </Box>
                {props.coverPath && (
                    <Box className={classes.src}>
                        <Image
                            src={props.coverPath}
                            loader={ImageLoader}
                            alt="teacher"
                            width={360}
                            height={140}
                            layout="intrinsic"
                            objectFit="cover"
                        />
                    </Box>
                )}
                <Box className={classes.lineContainer}>
                    <Box className={classes.line}></Box>
                    <Typography variant="h6" className={classes.start}>
                        Бүртгэл эхлэх огноо
                    </Typography>
                    <Box className={classes.line}></Box>
                </Box>
                <Typography variant={'h1'} className={classes.time}>
                    {moment(props.openDate).format('YYYY/MM/DD HH:mm')}
                </Typography>
                <Box className={classes.buttons}>
                    <Button className={classes.sign} onClick={props.onClick}>
                        {props.buttonText}
                    </Button>
                    {props.userType === 'admin' && (
                        <Button className={classes.sign} onClick={props.onPublish}>
                            {props.status === 'hidden' ? 'Зарлах' : 'Цуцлах'}
                        </Button>
                    )}
                </Box>
                {props.userType === 'admin' && (
                    <>
                        <Box className={classes.actionBox}>
                            <Box className={classes.actionButton} onClick={handleEdit}>
                                <EditIcon />
                            </Box>
                            <Box className={classes.actionButton} onClick={handleDelete}>
                                <DeleteIcon />
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

OlympiadCard.defaultProps = {
    src: AwardIcon,
    openDate: '2022.03.15 16:05',
    buttonText: 'Дэлгэрэнгүй',
    lesson: 'Математик',
    grade: '12-р анги',
    userTyp: 'student',
    title: 'МХТС-ийн нэрэмжит “Инженерчлэлийн эдийн засаг” -ийн II НЭЭЛТТЭЙ олимпиад',
};

export default OlympiadCard;

const time = {
    Өдөр: 0,
    Цаг: 0,
    Минут: 0,
    Секунд: 0,
};
